import React from 'react';
import Image from 'next/image';
import Link from "next/link";
import ArrowRight from "../icons/ArrowRight";

type Props = {
  image_align?: 'left' | 'right';
  image: string;
  small?: boolean;
  description: string,
  title: string,
  link_href?: string,
  link_text?: string,
  ctas?: Cta[]
};

interface Cta {
  text: string;
  href: string;
  image: string;
  width: number;
  height: number;
};

const renderImage = (small: boolean, image: string) => {
  if (!image) return
  return small ? (
    <Image
      src={image}
      alt=""
      className="aspect-[400/400] rounded-3xl object-cover"
      layout="intrinsic"
      height={400}
      width={400}
      loading="lazy"
      quality={100}
    />
  ) : (
    <Image
      src={image}
      alt=""
      className="aspect-[618/400] rounded-3xl object-cover"
      layout="fill"
      loading="lazy"
      quality={100}
    />
  )
}

const TwoColumn: React.FC<Props> = ({ image_align = 'right', image, small = false, title, description, link_href, link_text, ctas }) => {
  return (
    <div className="container mb-8 sm:mb-14 md:mb-20">
      <div className="mx-auto max-w-[1400px]">
        <div className="grid gap-6 sm:grid-cols-2 items-center">
          <div
            className={`relative flex min-h-[235px] sm:h-[400px] ${image_align === 'left' ? 'justify-start sm:order-1' : 'justify-end md:order-2'
              } ${small ? (image_align === 'left' ? 'max-w-[254px] place-self-start' : 'max-w-[254px] place-self-end') : ''
              }`}>
            {
              link_href
                ? <Link href={link_href}><a>
                  {renderImage(small, image)}
                </a></Link>
                : renderImage(small, image)
            }
          </div>
          <div
            className={`flex flex-col justify-center sm:p-4 md:p-16 ${image_align === 'left' ? 'sm:order-2' : 'sm:order-1'}`}>
            <h4 className="heading-h3 mb-2">{title}</h4>
            <p className="text-base">{description}</p>
            {link_href &&
              <div className="mr-auto mt-4">
                <Link href={link_href}><a className="btn-black mx-auto">
                  <span className="mr-3">{link_text}</span>
                  <ArrowRight color="white" />
                </a></Link>
              </div>
            }
            {ctas && ctas.length > 0 && (
              <div className="flex justify-evenly mt-6">
                {ctas.map((cta: Cta, index: number) => (
                    <Link key={index} href={cta.href}>
                      <a>
                        <Image src={cta.image} alt={cta.text} width={cta.width} height={cta.height} />
                      </a>
                    </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TwoColumn);
